import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthfakeauthenticationService } from '../services/authfake.service';
@Injectable()
export class CustomInterceptor implements HttpInterceptor {
   /*  constructor(private tokenExtractor: HttpXsrfTokenExtractor) {
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const cookieheaderName = 'X-XSRF-TOKEN';
        let csrfToken = this.tokenExtractor.getToken() as string;
        if (csrfToken !== null && !req.headers.has(cookieheaderName)) {
            req = req.clone({ headers: req.headers.set(cookieheaderName, csrfToken) });
        }
        return next.handle(req);
    } */
    constructor(private authfackservice: AuthfakeauthenticationService,private tokenExtractor: HttpXsrfTokenExtractor) { }
/**
 * Adding csrfToken to api headers
 */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        let headers = request.headers;
        //const currentUser = this.authfackservice.currentUserValue;
       
        let csrfToken = this.tokenExtractor.getToken() as string;
        const cookieheaderName = 'X-XSRF-TOKEN';
        ////console.log(currentUser);
        if ( csrfToken!=null) {
            //let csrfToken = currentUser['csrfToken'];
            request = request.clone({ headers: request.headers.set(cookieheaderName, csrfToken) });
        }
        return next.handle(request);
        //append csrf header for all but safe methods
       /*  if (!['GET','HEAD','OPTIONS'].includes(request.method)){
           headers = headers.append("X-XSRF-TOKEN-B", csrfToken);
      }

        const updatedRequest = request.clone({ headers });
        return next.handle(updatedRequest) */
    }
}