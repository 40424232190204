<div class="account-pages my-5 pt-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center">
                    <h1 class="display-2 fw-medium">4<img loading="lazy" src="assets/images/tyre.svg" class=" bx-spin mb-2 mb-md-3 wheel" >4</h1>
                    <h4 class="text-uppercase">Sorry, page not found</h4>
                    
                </div>
            </div>
        </div>
        <div class="row justify-content-start">
            <no-record text=" " btnRoute="/" btnText="Back to Dashboard" ></no-record>
        </div>
    </div>
</div>