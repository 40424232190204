import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        link: '/dashboard',
        badge: {
            variant: 'info',
            text: 'MENUITEMS.DASHBOARDS.BADGE',
        },
    },
    {
        id: 2,
        label: 'MENUITEMS.USERMANAGEMENT.TEXT',
        isTitle: true
    },
    {
        id: 3,
        label: 'MENUITEMS.ROLES&PERMISSIONS.TEXT',
        icon: 'bx-sitemap',
        link: '/roles-and-permissions',

    },
  
    {
        id: 4,
        label: 'MENUITEMS.USERS.TEXT',
        icon: 'bx-admin',
        link: '/users',
    },
    
    {
        id: 5,
        label: 'MENUITEMS.SUPPLIER.TEXT',
        icon: 'bx-supplier',
        subItems: [
            {
                id: 1,
                label: 'MENUITEMS.SUPPLIER.LIST.ADDSUPPLIER',
                link: '/supplier/add-supplier',
                parentId: 5
            },
            {
                id: 2,
                label: 'MENUITEMS.SUPPLIER.LIST.VIEWSUPPLIERS.TEXT',
                link: '/supplier/view-suppliers',
                badge: {
                    variant: 'success',
                    text: 'MENUITEMS.SUPPLIER.LIST.VIEWSUPPLIERS.BADGE',
                },
                parentId: 5
            }
        ]
    },
    {
        id: 6,
        label: 'MENUITEMS.CUSTOMER.TEXT',
        icon: 'bxs-user',
        subItems: [
            {
                id: 1,
                label: 'MENUITEMS.CUSTOMER.LIST.ADDCUSTOMER',
                link: '/customer/add-customer',
                parentId: 6
            },
            {
                id: 2,
                label: 'MENUITEMS.CUSTOMER.LIST.VIEWCUSTOMERS.TEXT',
                link: '/customer/view-customers',
                badge: {
                    variant: 'success',
                    text: 'MENUITEMS.CUSTOMER.LIST.VIEWCUSTOMERS.BADGE',
                },
                parentId: 6
            }
        ]
    },

    {
        id: 4,
        label: 'Deactivate Accounts',
        icon: 'bx-admin',
        link: '/accountDeleteing',
    },
    {
        id: 7,
        label: 'MENUITEMS.PRODUCTMANAGEMENT.TEXT',
        isTitle: true
    },
    {
        id: 8,
        label: 'MENUITEMS.CATEGORY.TEXT',
        icon: 'bxs-category',
        subItems: [
            {
                id: 1,
                label: 'MENUITEMS.CATEGORY.LIST.ADDCATEGORY',
                link: '/category/add-category',
                parentId: 8
            },
            {
                id: 2,
                label: 'MENUITEMS.CATEGORY.LIST.VIEWCATEGORIES',
                link: '/category/view-categories',
                parentId: 8
            }
        ]
    },
    {
        id: 9,
        label: 'MENUITEMS.PRODUCT.TEXT',
        icon: 'bxs-truck',
        subItems: [
            {
                id: 1,
                label: 'MENUITEMS.PRODUCT.LIST.ADDPRODUCT',
                link: '/product/add',
                parentId: 9
            },
            {
                id: 2,
                label: 'MENUITEMS.PRODUCT.LIST.PRODUCTLISTING',
                link: '/product/listing',
                parentId: 9
            }
        ]
    },
    {
        id: 10,
        label: 'MENUITEMS.INQUIRIES.TEXT',
        icon: 'bxs-enquiry',
        link: '/inquiries',

    },
    {
        id: 11,
        label: 'MENUITEMS.RENTALS.TEXT',
        icon: 'bxs-package',
        link: '/rentals',

    },
    {
        id: 17,
        label: 'MENUITEMS.PAYMENT_DETAILS.TEXT',
        icon: 'bx-dollar',
        link: '/all-payments',

    },
    {
        id: 19,
        label: 'MENUITEMS.PAYMENT_DISBURSEMENT.TEXT',
        icon: 'bx-transfer',
        link: '/payment-disbursement',

    },
    {
        id: 18,
        label: 'MENUITEMS.ALL_DELIVERIES.TEXT',
        icon: 'bxs-rocket',
        link: '/all-deliveries',

    },
    {
        id: 12,
        label: 'MENUITEMS.TRACKING.TEXT',
        icon: 'bx-trip',
        link: '/tracking',
    },

    {
        id: 16,
        label: 'MENUITEMS.CHAT_MANAGEMENT.TEXT',
        icon: 'bxs-conversation',
        subItems: [
            {
                id: 1,
                label: 'MENUITEMS.CHAT_MANAGEMENT.LIST.CHAT',
                link: '/chatmanage',
                parentId: 9
            },
            {
                id: 2,
                label: 'MENUITEMS.CHAT_MANAGEMENT.LIST.AMOUNTDIsBURSEMENT',
                link: '/amount-disbursement',
                parentId: 9
            }
        ]
    },
    // {
    //     id: 16,
    //     label: 'Chat',
    //     icon: '',
    //     link: '',
    // },
    {
        id: 16,
        label: 'MENUITEMS.OTHERS.TEXT',
        isTitle: true
    },
    {
        id: 13,
        label: 'MENUITEMS.NOTIFICATIONS.TEXT',
        icon: 'bxs-notification',
        link: '/notifications',
    },
    {
        id: 14,
        label: 'MENUITEMS.FEEDBACKS.TEXT',
        icon: 'bxs-user-voice',
        link: '/feedbacks',
    },
    {
        id: 15,
        label: 'MENUITEMS.CONTACT_US.TEXT',
        icon: 'bxs-message-detail',
        link: '/contact-support/list',
    },

]

