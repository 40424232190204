import { Component , OnInit} from '@angular/core';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from 'src/environments/environment';
import { onBackgroundMessage } from "firebase/messaging/sw";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  title = 'af-notification';
  message:any = null;
  constructor() {}
  ngOnInit() {
/*     this.requestPermission();
    this.listen(); */
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  }

  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging,
     { vapidKey: environment.firebase.WebNotificationKey}).then(
       (currentToken) => {
         if (currentToken) {
           //console.log("Hurraaa!!! we got the token.....");
           //console.log(currentToken);
         } else {
           //console.log('No registration token available. Request permission to generate one.');
         }
     }).catch((err) => {
        //console.log('An error occurred while retrieving token. ', err);
    });
  }
  listen() {
    //console.log('listen')
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      //console.log('Message received. ', payload);
      this.message=payload;
    });
  }
}
