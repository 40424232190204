<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img loading="lazy" src="assets/images/logo.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img loading="lazy" class="mt-2" src="assets/images/logo-dark.svg" alt="" height="50">
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img loading="lazy" src="assets/images/logo.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img loading="lazy" class="mt-2" src="assets/images/logo-light.svg" alt="" height="50">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="bx bx-menu-alt-left font-size-32"></i>
      </button>

      <!-- App Search-->
      <!-- <form class="app-search d-none d-xl-block">
        <div class="position-relative">
          <input type="text" class="form-control" placeholder="{{'HEADER.SEARCH' | translate}}">
          <span class="bx bx-search-alt"></span>
        </div>
      </form> -->
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" data-toggle="dropdown"
          aria-haspopup="true" ngbDropdownToggle aria-expanded="false">
          <i class="bx bx-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
          aria-labelledby="page-header-search-dropdown">
          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="bx bx-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown #myDrop="ngbDropdown">
        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
          ngbDropdownToggle (click)="openNotification()" >
          <i #notifyBell class="bx bx-bell"></i>
          <span  *ngIf="notifications.length > 0" class="badge bg-danger rounded-pill">{{notifications.length}}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 rounded-3 mt-2"
          aria-labelledby="page-header-notifications-dropdown " ngbDropdownMenu>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">{{ 'HEADER.NOTIFICATIONS.TITLE' | translate}} </h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" (click)="onClearAll()" class="small">{{ 'HEADER.NOTIFICATIONS.CLEAR' | translate}}</a>
              </div>
            </div>
          </div>
          <ngx-simplebar style="position: relative; height: 230px;">
            <ng-container *ngIf="notifications.length > 0;else noNotifications">
              <a *ngFor="let notification of notifications" href="javascript: void(0);" class="text-reset notification-item">
                <div class="media">
                  <div class="avatar-xs me-3">
                    <span class="avatar-title bg-primary rounded-circle font-size-16">
                      <i class="bx bx-cart"></i>
                    </span>
                  </div>
                  <div class="media-body">
                    <h6 class="mt-0 mb-1">{{ notification.title }}</h6>
                    <div class="font-size-12 text-muted">
                      <p class="mb-1">{{ notification.message }}</p>
                      <!-- <p class="mb-1" *ngIf="notification.link != '' && notification.link != null && notification.link != undefined"><a href="{{notification.link}}">{{ notification.link }}</a></p> -->
                      <p class="mb-0"><i class="bx bx-clock-outline"></i>
                        {{ notification.createdAt | amTimeAgo}}</p>
                      </div>
                    </div>
                  </div>
                </a>
              </ng-container>
              <ng-template #noNotifications>
                <div class="d-flex flex-column p-5  text-center overflow-hidden">
                  <img loading="lazy" src="/assets/images/no_notifications.svg" alt="no_notifications" class="img-fluid width-fit mx-auto height-fit">
                  <p class="mt-1">No Notification has arrived...</p>
                </div>
              </ng-template>
            
           <!--  <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <img loading="lazy" src="assets/images/users/avatar-4.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="bx bx-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.FOUR.TIME' |
                      translate}}</p>
                  </div>
                </div>
              </div>
            </a> -->
          </ngx-simplebar>
          <div class="p-2 border-top d-grid">
            <a class="btn btn-sm btn-link font-size-14 text-center" routerLink="/all-notifications" (click)="$event.stopPropagation(); myDrop.close();" href="javascript:void(0)">
              <i class="bx bx-arrow-right-circle me-1"></i> <span key="t-view-more">{{
                'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}</span>
            </a>
          </div>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item d-inline-flex align-items-center ps-1" ngbDropdownToggle id="page-header-user-dropdown">
          <div *ngIf="userData.profile === null || userData.profile === '' ;else profileImg" class="header-profile-user avatar-xs mb-1 ">
            <span class="avatar-title rounded-circle">
              {{userData.name[0].toUpperCase()}}
            </span>
          </div> 
          <ng-template #profileImg>
            <img loading="lazy" class="rounded-circle header-profile-user mb-1"  [src]="userData.profile" alt="Header Avatar">
          </ng-template>
          <span class="d-none d-xl-inline-block ms-1 mb-1">{{userData.name}}</span>
          <i class="bx bx-chevron-down d-none d-xl-inline-block mb-1"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end mt-2" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>

    </div>
  </div>
  <div id="newNotification" class="position-fixed bottom-0 end-0 p-3" style="z-index: 1005">
    <ngb-toast *ngIf="showToast"
    [delay]="6000"
    [autohide]="autohideToast"
    (mouseenter)="autohideToast = true"
    (mouseleave)="autohideToast = false"
    (hide)="showToast = false; autohideToast = true"
    [class.bg-light]="!autohideToast">
        <ng-template ngbToastHeader>
            <img loading="lazy" src="assets/images/logo.svg" alt="" class="me-2" height="18">
                <strong class="me-auto">{{message.title}}</strong>
                <small>now</small>
        </ng-template>
        {{message.body}}
    </ngb-toast>
</div>   
</header>