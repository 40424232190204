import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'no-record',
  templateUrl: './no-record.component.html',
  styleUrls: ['./no-record.component.scss']
})
export class NoRecordComponent implements OnInit {

  @Input() text: string;
  imageURL: string;
  @Input() btnText: string;
  @Input() btnRoute: string;
  @ViewChild('noRecordImg') noRecordImg:ElementRef;
  @ViewChild('wrapperDiv') wrapperDiv:ElementRef;
  imagesList:any
  image:any
  wrapper:any
  @Input() autoCenter = true;
  
  constructor( ) { }

  ngOnInit(): void {
    this.imagesList = ['assets/images/equipment_no_record.svg', 
    'assets/images/truck_no_record.svg', 'assets/images/fork_lift_no_record.svg', 
    'assets/images/jcb_no_record.svg'];

    this.imageURL = this.imagesList[Math.floor(Math.random() * this.imagesList.length)];
  }

  onLoad() {
    if( this.autoCenter ){
      this.image = (this.noRecordImg.nativeElement as HTMLImageElement)
      this.wrapper = (this.wrapperDiv.nativeElement as HTMLElement)
      this.image.style.width = this.image.naturalWidth
      this.wrapper.style.height = window.innerHeight - ( 94 + this.image.naturalWidth/2 + 36 ) +'px';
    }
  }
}
