import { Component, OnInit , Input } from '@angular/core';
import { LoaderService } from "../../../core/services/loader.service";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input() loading: boolean;
  @Input() apiBased = false;

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    if(!this.apiBased){
      this.loading = true;
      //console.log(this.apiBased)
      this.loaderService.isLoading.subscribe((v) => {
        setTimeout(() => {
          this.loading = v;
        }, 1500);     
      });
    }
  }

}
