<div class="home-btn d-none d-sm-block">
  <a routerLink="/" class="text-dark"><i class="fas fa-home h2"></i></a>
</div>

<section class="my-5 pt-sm-5">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <div class="home-wrapper">
          <div class="mb-5">
            <a routerLink="/" class="d-block auth-logo">
              <img loading="lazy" src="assets/images/logo-dark.svg" alt="" height="50" class="auth-logo-dark mx-auto">
              <img loading="lazy" src="assets/images/logo-light.svg" alt="" height="50" class="auth-logo-light mx-auto">
            </a>
          </div>

          <div class="row justify-content-center">
            <no-record text=" " [autoCenter]="false"></no-record>
            <!-- <div class="col-sm-4">
              <div class="maintenance-img">
                <img loading="lazy" src="assets/images/maintenance.svg" alt="" class="img-fluid mx-auto d-block">
              </div>
            </div> -->
          </div>
          <h3 class="mt-5">Site is Under Maintenance</h3>
          <p>Please check back in sometime.</p>

          <div class="row">
            <div class="col-md-4">
              <div class="card mt-4 maintenance-box">
                <div class="card-body">
                  <i class="bx bx-broadcast mb-4 h1 text-dayim"></i>
                  <h5 class="font-size-15 text-uppercase">Why is the Site Down?</h5>
                  <p class="text-muted mb-0">The website is undergoing maintenance or updates. We should be back up and running soon. Thank you for your patience!</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card mt-4 maintenance-box">
                <div class="card-body">
                  <i class="bx bx-time-five mb-4 h1 text-dayim"></i>
                  <h5 class="font-size-15 text-uppercase">
                    What is the Downtime?</h5>
                  <p class="text-muted mb-0">
                    The downtime for a website is the time when the website is unavailable to users. This can be due to maintenance, updates, or technical issues.</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card mt-4 maintenance-box">
                <div class="card-body">
                  <i class="bx bx-envelope mb-4 h1 text-dayim"></i>
                  <h5 class="font-size-15 text-uppercase">
                    Do you need Support?</h5>
                  <p class="text-muted mb-0">If you have any questions or need support, contact us via email. We will be happy to assist you.
                    <br><a href="mailto:admin@rentalshare.com"
                      class="text-decoration-underline">admin@rentalshare.com</a></p>
                </div>
              </div>
            </div>
          </div>
          <!-- end row -->
        </div>
      </div>
    </div>
  </div>
</section>