import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthfakeauthenticationService } from '../services/authfake.service';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {


    constructor(
        private router: Router,
        private helper: JwtHelperService,
        private authFackservice: AuthfakeauthenticationService
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, ) {
        //checking access token is there in localstorage and not expired then login
        const accessToken = localStorage.getItem('accessToken');
        const isExpired = this.helper.isTokenExpired(accessToken);
        const rememberMeURL = sessionStorage.getItem('deviceId') ? `/auth/remember?deviceId=${sessionStorage.getItem('deviceId')}` : '/auth/remember';

        if(accessToken && !isExpired){
            if(await this.authFackservice.rememberMe(rememberMeURL)){
                return true;
            }
        }
        // not logged in so redirect to login page with the return url
        //  this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
       
        this.router.navigate(['/account/login']);
        return false;
        
        
    }
}
