// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultauth: 'fackbackend',
  apiUrl: 'https://dev-api.dayimrentalshare.com',
  // apiUrl: 'https://dev-api.dayimrentalshare.com',
  // apiVersion: 'v1',
  // apiUrl: 'http://localhost:3000',
  // apiUrl: 'https://api.dayimrentalshare.com',
  appURL: 'https://rentalshare.kpi.co',
  // apiUrl: 'https://api.dayimrentalshare.com',

  apiVersion: 'v1',
  // apiUrl: 'http://localhost:3000',
  // apiUrl: 'https://dev-api.dayimrentalshare.com',
//   appURL: 'https://dev-admin.dayimrentalshare.com',
  // apiUrl: 'https://api.dayimrentalshare.com',
  // appURL: 'https://admin.dayimrentalshare.com',

  userURL: 'https://user.dayimrentalshare.com/product/',
  secretKey: 'qCqSqQjvW8',
  firebase: {
    apiKey: "AIzaSyA42HQ4kR7ToQlGdQbOWsBBeXNlqZy-H44",
    serverKey: "AAAAZTff-u0:APA91bG7K52FDJipQ4ab0hJEhCGKbOPxFd5ydIhW9Oq5BthkhdyYoA5ekX23vj_7C2SRxF28pVJ8b-wekvwFpcKa7R53noeGyh--JcVAU_RmZfbiFQbu8s8K4Oi5cnwPFe1e5qLsfMri",
    WebNotificationKey: "BBcRamxjhakTL9RZ0pFwBVnw_zktvTDW6xJrRU7F8LyAIFHstiPBPTZtANkmKqQrPL8829kuxjLDKgu3-ORd3LE",
    authDomain: "imposing-avatar-359209.firebaseapp.com",
    projectId: "imposing-avatar-359209",
    storageBucket: "imposing-avatar-359209.appspot.com",
    messagingSenderId: "434729122541",
    appId: "1:434729122541:web:0809c8db84908e739f6ae4",
    measurementId: "G-KXZ9FYGE63",
  },
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
