import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  baseUrl: string = environment.apiUrl;
  apiVersion: string = environment.apiVersion;

  permissionOptions = {
    headers: new HttpHeaders({
        //'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${auth_token}`
        'Permission-Name': "Product Management"
    })
}
  constructor(private http: HttpClient) { }
  getList(url) {
    return this.http.get(`${this.baseUrl}/${this.apiVersion}${url}`,this.permissionOptions);
  }
  onCreate(url,data){
    return this.http.post(`${this.baseUrl}/${this.apiVersion}${url}`, data,this.permissionOptions);
  }
  clearAll(url){
    return this.http.delete(`${this.baseUrl}/${this.apiVersion}${url}`, this.permissionOptions);
  }
}
