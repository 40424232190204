import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { User } from '../models/auth.models';
import { environment } from 'src/environments/environment';
import { UseraccountService } from 'src/app/account/auth/useraccount.service';

@Injectable({ providedIn: 'root' })
export class AuthfakeauthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    baseUrl: string = environment.apiUrl;
    apiVersion: string = environment.apiVersion;

    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
    }
    verifyOtpToken = '';
    constructor(private http: HttpClient,private cookieService: CookieService, private useraccountService: UseraccountService) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        //console.log(this.currentUserSubject.value);
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }
    loginCheck(url, data){
       /*  return this.http.get<any>(`${this.baseUrl}/${this.apiVersion}${url}`).pipe(map(user => {
            // login successful if there's a jwt token in the response
            //console.log(user.accessToken);
            if (user && user.accessToken) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
            }
            return user;
        }));; */
        return this.http.post(`${this.baseUrl}/${this.apiVersion}${url}`, data);
    }
    login(email: string, password: string) {
        return this.http.post<any>(`/users/authenticate`, { email, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
       // localStorage.removeItem('currentUser');
       //console.log('inside logout')
       localStorage.removeItem('accessToken');
       localStorage.removeItem('user');
        this.currentUserSubject.next(null);
    }

    resetPassword(url){
        return this.http.get(`${this.baseUrl}/${this.apiVersion}${url}`);
    }

    verifyOTP(url,data){
        let httpOptions = {
            headers: new HttpHeaders({
              //'Content-Type': 'application/json',
              'Authorization': `Bearer ${this.useraccountService.verifyOtpToken}`

            })
          }
        return this.http.post(`${this.baseUrl}/${this.apiVersion}${url}`,data,httpOptions);
    }

    updatePassword(url,data){
        let httpOptions = {
            headers: new HttpHeaders({
              //'Content-Type': 'application/json',
              'Authorization': `Bearer ${this.useraccountService.verifyOtpToken}`

            })
          }
        return this.http.put(`${this.baseUrl}/${this.apiVersion}${url}`,data,httpOptions);
    }

    async rememberMe(url: string){
        return new Promise( (resolve,reject) => {
            this.http.get(`${this.baseUrl}/${this.apiVersion}${url}`).toPromise().then(data => {
                //console.log('Promise resolved.')
                resolve(true)
            },error => {
                //console.log('Promise not resolved.')
                resolve(false)
            });
        })
    }
    addDevice(url,data){
         return this.http.post(`${this.baseUrl}/${this.apiVersion}${url}`,data);
     }
}
